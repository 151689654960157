export let addRule = {
    discount_amount: [
        { required: true, message: "请填写售价", trigger: "change" },
    ],
    name: [
        { required: true, message: "请填写名称", trigger: "change" },
    ],
    amount: [
        { required: true, message: "请填写原价", trigger: "change" },
    ],

}

